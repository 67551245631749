import { Avatar } from 'src/components/Display/Avatar'
import { CommonStyles } from './styles'
import { getFriends } from 'src/models/friend'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { Link } from 'src/components/Link'
import { Loader } from 'src/components/Display/Loader'
import { mixin, breakpoint } from 'src/styles'
import { useAsync } from 'react-async-hook'
import { useMemberContext } from 'src/providers/MemberProvider'
import { useResponsive } from 'src/hooks/useResponsive'
import { Username } from 'src/components/Display/Username'

export const FriendsOverviewCard = () => {
  const { isSelf, currentMember, username } = useMemberContext()
  const { result, loading } = useAsync(getFriends, [currentMember?.id, 1, 8])
  const { isMediumAndUp } = useResponsive()
  const maxFriendsLength = isMediumAndUp ? 6 : 8
  const isOnlyFriend = result?.totalResults === 1

  return (
    <Styles.Container>
      <CommonStyles.Title center compact centraLight>
        Friends
      </CommonStyles.Title>
      <CommonStyles.CardSubtitle>
        {isSelf ? 'My' : `${getMemberDisplayName(currentMember, { useFirstName: true })}'s`} obé
        Friends
      </CommonStyles.CardSubtitle>
      <Styles.FriendsContainer>
        <Loader isLoading={loading}>
          <Styles.Grid isOnlyFriend={isOnlyFriend}>
            {result?.data?.friendsList?.slice(0, maxFriendsLength).map(
              ({
                id,
                friendUser: {
                  profile: { username, firstName, lastName, avatarUrl },
                  account: { annual, quarterly },
                },
              }) => (
                <Styles.FriendContainer key={id} to={`/${username}`} isOnlyFriend={isOnlyFriend}>
                  <Styles.InnerFriendContainer>
                    <Styles.AvatarContainer>
                      {React.createElement(Styles.Avatar, {
                        avatarUrl,
                        firstName,
                        lastName,
                        foundingMember: false,
                        isLevelUp: annual || quarterly,
                      })}
                    </Styles.AvatarContainer>
                    {isMediumAndUp && (
                      <Styles.DetailsContainer>
                        <Styles.Name>
                          {getMemberDisplayName(
                            { profile: { username, firstName, lastName } },
                            { useFullNameWithInitial: true },
                          )}
                        </Styles.Name>
                        <Styles.Username username={username} />
                      </Styles.DetailsContainer>
                    )}
                  </Styles.InnerFriendContainer>
                </Styles.FriendContainer>
              ),
            )}
          </Styles.Grid>
          {result?.totalResults > maxFriendsLength && (
            <CommonStyles.RedirectLink friendsSeeAll to={`/${username || '/profile'}/friends`}>
              See All
            </CommonStyles.RedirectLink>
          )}
        </Loader>
      </Styles.FriendsContainer>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.shadowContainer}
    padding: 16px;

    ${breakpoint.large} {
      margin-left: 20px;
    }
  `,
  FriendsContainer: styled.div`
    ${mixin.flexCenterBoth}
    flex-direction: column;
  `,
  Grid: styled.div`
    ${mixin.flexCenterVertical}
    flex-wrap: wrap;
    flex: 1;
    width: 100%;

    > * {
      flex: 0 0 33%;
      margin-bottom: 1.5rem;
      max-width: 33%;
      padding: 0 8px;

      ${breakpoint.smallAndDown} {
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 1rem;
      }
    }

    ${({ isOnlyFriend }) =>
      isOnlyFriend &&
      css`
        justify-content: center;
      `}

    ${breakpoint.mediumAndUp} {
      padding: 0 1rem;
    }
  `,
  FriendContainer: styled(Link)`
    ${mixin.flexCenterVertical}

    :hover {
      text-decoration: none;
    }

    ${({ isOnlyFriend }) =>
      isOnlyFriend &&
      css`
        justify-content: center;
      `}

    ${breakpoint.smallAndDown} {
      justify-content: center;
    }
  `,
  InnerFriendContainer: styled.div`
    ${mixin.flexCenterVertical}
  `,
  AvatarContainer: styled.div`
    ${mixin.flexCenterVertical}

    >div {
      height: 60px;
      width: 60px;
    }

    ${breakpoint.mediumAndUp} {
      margin-right: 12px;
    }
  `,
  Avatar: styled(Avatar)`
    font-size: 24px;
  `,
  DetailsContainer: styled.div`
    ${mixin.flexCenterHorizontal}
    flex-direction: column;
    line-height: 1;
    word-break: break-all;
  `,
  Name: styled.div`
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 300;
    text-align: left;
  `,
  Username: styled(Username)`
    margin-top: 6px;
  `,
}
