import { useCallback, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { NotificationTooltip } from 'src/components/NotificationTooltip'
import { useMemberContext } from 'src/providers/MemberProvider'
import type { BookmarkCollectionType } from 'src/types/BookmarkCollections'

interface Props {
  initialShowing: boolean
  collection: Omit<BookmarkCollectionType, 'obeClasses'>
}

export const BookmarkCollectionCardTooltip = ({ initialShowing, collection }: Props) => {
  const { isSelf } = useMemberContext()
  const [isShowingTooltip, setIsShowingTooltip] = useState(initialShowing)

  const getTooltipCopy = useCallback(() => {
    if (isSelf) {
      return collection.numUpdates > 1 ? 'New Updates!' : 'New Update!'
    }
    return collection.numUpdates > 1 ? 'New Followers!' : 'New Follower!'
  }, [collection.numUpdates, isSelf])

  useEffect(() => {
    setTimeout(() => setIsShowingTooltip(false), 5000)
  }, [])

  return isSelf && collection.numUpdates > 0 ? (
    <CSSTransition
      classNames='fade'
      in={isShowingTooltip}
      timeout={300}
      appear={isShowingTooltip}
      unmountOnExit
    >
      <div
        className="absolute bottom-[1px] z-10 transition-opacity duration-300 [&.fade-enter-active]:opacity-100
          [&.fade-enter]:opacity-0 [&.fade-exit]:opacity-0"
      >
        <Tooltip
          isShowing={isShowingTooltip}
          content={getTooltipCopy()}
          direction='bottom'
          showDismiss={false}
        />
      </div>
    </CSSTransition>
  ) : null
}

const Tooltip = styled(NotificationTooltip)<{ isShowing: boolean }>`
  position: absolute;
  left: 199px;
  bottom: 42px;
  white-space: nowrap;
  width: 'fit-content';
  height: 50px;
  border-radius: 10px;
  border: 2px solid var(--persianBlue);
  z-index: 10;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 16.8px;
  }

  ${({ isShowing }) =>
    !isShowing &&
    css`
      display: none;
    `}
`
