import { useEffect, useState, type MouseEvent, type MouseEventHandler } from 'react'
import { useAsyncCallback } from 'react-async-hook'

import { Button } from 'src/components/Button/Button'
import { Dropdown } from 'src/components/DropdownMenu'
import { Icon } from 'src/components/Icon'
import { Bookmark as BookmarkIcon } from 'src/components/Icon/Bookmark'
import { FilledBookmark } from 'src/components/Icon/FilledBookmark'
import { actions, useToastContext } from 'src/components/Toast'
import { setBookmarkCollections, useBookmarkCollections } from 'src/hooks/useBookmarkCollections'
import { useResponsiveProps } from 'src/hooks/useResponsiveProps'
import { setSavedClasses, useSavedClasses } from 'src/hooks/useSavedClasses'
import { useUiState } from 'src/hooks/useStores'
import { addVideoToBookmarkCollection } from 'src/models/bookmarkCollection'
import { COLLECTION_TYPES } from 'src/models/collections'
import { saveObeClass, unsaveObeClass } from 'src/models/savedObeClass'
import type { ObeClassType } from 'src/types/ObeClass'

interface Props {
  video: ObeClassType
  className?: string
  hideFavoritedHeart?: boolean
  isClassHistory?: boolean
  tracking?: { onBookmark?: () => void; onUnbookmark?: () => void }
  trackingContext?: Record<string, string | number>
  isFullButton?: boolean
  iconOnly?: boolean
}

export const BookmarkWithDropdown = ({
  className,
  hideFavoritedHeart = false,
  isClassHistory = false,
  tracking: { onBookmark: _onBookmarkTracking, onUnbookmark: _onUnbookmarkTracking } = {},
  trackingContext = {},
  video,
  isFullButton = false,
  iconOnly = false,
}: Props) => {
  const { bookmarkCollections } = useBookmarkCollections()
  const { favoriteVideos, savedVideos } = useSavedClasses()
  const { toastDispatch } = useToastContext()
  const { setBookmarkVideoDialog } = useUiState()

  const [isBookmarked, setIsBookmarked] = useState(savedVideos?.includes(video?.id))
  const [isFavoritedLocally, setIsFavoritedLocally] = useState(false)
  const isFavorited = favoriteVideos?.includes(video?.id) || isFavoritedLocally
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const displayIconOnly = useResponsiveProps(
    {
      isSmallAndDown: true,
      isMedium: true,
      isLargeAndUp: false,
    },
    false,
  ) as boolean

  useEffect(() => {
    if (dropdownOpen) setTimeout(() => setDropdownOpen(false), 4000)
  }, [dropdownOpen])

  useEffect(() => {
    if (disabled) setTimeout(() => setDisabled(false), 2500)
  }, [disabled])

  const { execute: executeBookmark, loading: bookmarkLoading } = useAsyncCallback(async () => {
    const { data } = await saveObeClass(video.id, 'VOD page')

    if (data) {
      toastDispatch({
        type: actions.ADD,
        payload: {
          content: 'Bookmark saved successfully',
          id: `${video.id}-added`,
          type: 'confirmation',
        },
      })
      const { favoriteVideos, savedVideos } = data
      setSavedClasses({ favoriteVideos, savedVideos })
    }
  })

  const { execute: executeFavorite, loading: favoriteLoading } = useAsyncCallback(async () => {
    const favorites = bookmarkCollections?.find(
      ({ name }) => name.toUpperCase() === COLLECTION_TYPES.FAVORITES.ALL_CAPS,
    )

    if (favorites?.id) {
      const { data } = await addVideoToBookmarkCollection(video.id, favorites?.id)

      if (data) {
        const { bookmarkCollections, favoriteVideos, savedVideos } = data

        obe.analytics.track(obe.events.bookmarks.saved_to_collection, {
          collectionId: favorites.id,
          collectionName: favorites.name,
          videoId: video?.id,
          isNew: false,
        })

        toastDispatch({
          type: actions.ADD,
          payload: {
            content: 'Bookmark saved to Favorites',
            id: `${video.id}-added-favorites`,
            type: 'confirmation',
          },
        })
        setBookmarkCollections(bookmarkCollections)
        setSavedClasses({ favoriteVideos, savedVideos })
      }
    }
  })

  const { execute: executeUnbookmark, loading: unbookmarkLoading } = useAsyncCallback(async () => {
    const { data } = await unsaveObeClass(video.id)

    if (data) {
      toastDispatch({
        type: actions.ADD,
        payload: {
          content: 'Bookmark removed successfully',
          id: `${video.id}-removed`,
          type: 'confirmation',
        },
      })
      const { bookmarkCollections, favoriteVideos, savedVideos } = data
      setBookmarkCollections(bookmarkCollections)
      setSavedClasses({ favoriteVideos, savedVideos })
    }
  })

  useEffect(() => {
    if (bookmarkLoading || favoriteLoading || unbookmarkLoading) setDisabled(true)
  }, [bookmarkLoading, favoriteLoading, unbookmarkLoading])

  const onBookmarkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!disabled) {
      if (!isBookmarked) {
        if (typeof _onBookmarkTracking === 'function') _onBookmarkTracking()
        executeBookmark()
        setIsBookmarked(true)
        setDropdownOpen(true)
        obe.analytics.track(obe.events.bookmarks.bookmark_vod, {
          ...trackingContext,
        })
      }

      if (isBookmarked) {
        if (typeof _onUnbookmarkTracking === 'function') _onUnbookmarkTracking()
        executeUnbookmark()
        setDropdownOpen(false)
        setIsBookmarked(false)
        setIsFavoritedLocally(false)
        obe.analytics.track(obe.events.bookmarks.removed_from_collection)
      }
    }
  }

  const onSaveToCollection = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setBookmarkVideoDialog({
      isOpen: true,
      video,
    })
    setDropdownOpen(false)
  }

  const onSaveToFavorites = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDropdownOpen(false)
    setIsFavoritedLocally(true)
    executeFavorite()
  }

  return (
    <div className={className}>
      <Button
        disabled={disabled}
        onClick={onBookmarkClick}
        variant={iconOnly || displayIconOnly ? 'icon' : 'secondary'}
        size='fit'
        className="group relative gap-2"
      >
        {isFullButton ? (
          <>
            {isBookmarked ? (
              <FilledBookmark
                className={'h-[21px] w-[14px] [&_path]:group-hover:fill-primary-500'}
              />
            ) : (
              <BookmarkIcon className="h-[21px] w-[14px] [&_path]:stroke-primary-400 [&_path]:group-hover:stroke-primary-500" />
            )}
            <span className="hidden lg:block">Bookmark</span>
          </>
        ) : isBookmarked ? (
          <FilledBookmark />
        ) : (
          <Bookmark hideFavoritedHeart={hideFavoritedHeart} />
        )}
        <Heart
          hideFavoritedHeart={hideFavoritedHeart}
          isFavorited={isFavorited}
          isFullButton={isFullButton}
          displayIconOnly={displayIconOnly}
        />
      </Button>
      <DropdownContainer
        className={className}
        isClassHistory={isClassHistory}
        isOpen={dropdownOpen}
      >
        <SaveToFavorites onClick={onSaveToFavorites} />
        <SaveToCollection onClick={onSaveToCollection} />
      </DropdownContainer>
    </div>
  )
}

const SaveToFavorites = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <MenuItem onClick={onClick}>
      <MenuHeart />
      SAVE TO FAVORITES
    </MenuItem>
  )
}

const SaveToCollection = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <MenuItem onClick={onClick}>
      <PlayCards />
      SAVE TO COLLECTION
    </MenuItem>
  )
}

const DropdownContainer = styled(Dropdown)`
  margin-top: 10px;
  border-radius: 0;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: 209px !important;
  top: 37px;
  right: 11px;
  ${({ isClassHistory }: { isClassHistory: boolean }) =>
    isClassHistory &&
    css`
      right: 1px !important;
      bottom: 30px !important;
      top: unset !important;
    `}
`
const Heart = styled(Icon.Heart)<{
  isFullButton: boolean
  displayIconOnly: boolean
  isFavorited: boolean
  hideFavoritedHeart: boolean
}>`
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3.35px;
  top: 8px;
  display: none;
  ${({ isFullButton, displayIconOnly }: { isFullButton: boolean; displayIconOnly: boolean }) =>
    isFullButton &&
    css`
      width: 8px;
      height: 8px;
      left: ${displayIconOnly ? 3 : 33.5}px;
      top: ${displayIconOnly ? 6 : 20}px;
      :not(:disabled):not(.disabled) path {
        fill: var(--white);
      }
      :not(:disabled):not(.disabled):hover {
        path {
          fill: var(--primary400);
        }
      }
    `}
  ${({ isFavorited, hideFavoritedHeart }: { isFavorited: boolean; hideFavoritedHeart: boolean }) =>
    isFavorited &&
    !hideFavoritedHeart &&
    css`
      fill: #fff;
      stroke: #fff;
      display: block;
    `}
`
const MenuItem = styled.button`
  color: var(--nearBlack);
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  font-size: 14px;
`
const MenuHeart = styled(Icon.Heart)`
  width: 19px;
  height: 19px;
  margin-right: 10px;
  margin-left: 0px !important;
  margin-top: 0px !important;
`
const PlayCards = styled(Icon.PlayCards)`
  width: 19px;
  height: 19px;
  margin-right: 10px;
  margin-left: 0px !important;
  margin-top: 0px !important;
`
const Bookmark = styled(BookmarkIcon)`
  ${({ hideFavoritedHeart }: { hideFavoritedHeart: boolean }) =>
    hideFavoritedHeart &&
    css`
      path {
        stroke: var(--white);
      }
    `}
`
