import { cva } from 'class-variance-authority'
import { useMemo } from 'react'

import type { ButtonVariant } from 'src/components/Button/Button'
import { SpinnerIcon } from 'src/components/Icon/Spinner'
import { cn } from 'src/utils/cn'

interface Props {
  variant: ButtonVariant
}

export const ButtonSpinner = ({ variant }: Props) => {
  const spinnerVariant = useMemo(() => {
    switch (variant) {
      case 'primary':
      default:
        return 'primary'
      case 'secondary':
      case 'icon':
      case 'link':
        return 'secondary'
    }
  }, [variant])

  return (
    <div>
      <SpinnerIcon className={cn(buttonSpinnerVariants({ variant: spinnerVariant }))} />
    </div>
  )
}

const buttonSpinnerVariants = cva(
  'animate-spinner flex h-5 w-5 shrink-0 flex-col items-center justify-center',
  {
    variants: {
      variant: {
        primary: 'stroke-white disabled:stroke-obe-neutral-700',
        secondary: 'stroke-primary-400 hover:stroke-primary-500 disabled:stroke-obe-neutral-700',
      },
      defaultVariants: {
        variant: 'primary',
      },
    },
  },
)
