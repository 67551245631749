import { useCallback, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { Lock } from 'src/components/Icon/Lock'
import { Link } from 'src/components/Link'
import { Typography } from 'src/components/Typography'
import { useMemberContext } from 'src/providers/MemberProvider'
import type { BookmarkCollectionType } from 'src/types/BookmarkCollections'
import { BookmarkCollectionCardImage } from 'src/views/Profile/BookmarkCollections/BookmarkCollectionCardImage'
import { BookmarkCollectionCardTooltip } from 'src/views/Profile/BookmarkCollections/BookmarkCollectionCardTooltip'
import { useUiState } from 'src/hooks/useStores'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { Profile } from 'src/types/User'

type Props = {
  collection: Omit<BookmarkCollectionType, 'obeClasses'>
  isFavorite?: boolean
  showTooltip?: boolean
} & (
  | {
      isFollowing: true
      author: Partial<Profile>
    }
  | {
      isFollowing?: false
      author?: 'self'
    }
)

export const BookmarkCollectionCard = ({
  collection,
  isFavorite = false,
  showTooltip = false,
  isFollowing = false,
  author = 'self',
}: Props) => {
  const { isSelf, currentMember, username } = useMemberContext()
  const { setBookmarkCollections } = useUiState()

  const toRoute = useMemo(() => {
    const user = (isFollowing && author !== 'self' ? author.username : username) || 'profile'
    if (!collection.id) return `/${user}/collections`
    if (isFavorite && !isFollowing) return `/${user}/collections/favorites`
    return `/${user}/collections/${collection.id}`
  }, [author, collection.id, isFavorite, isFollowing, username])

  const onClick = useCallback(() => {
    if (isSelf && !isFollowing) return
    setBookmarkCollections({ userFollowingCollection: currentMember })
  }, [currentMember, isFollowing, isSelf, setBookmarkCollections])

  return (
    <Link
      className="relative z-[3] flex flex-col gap-3 hover:no-underline"
      onClick={onClick}
      to={toRoute}
      action={obe.events.clicked_obe_collection}
      tracking={{ collectionName: collection.name, collectionId: collection.id }}
    >
      <BookmarkCollectionCardImage collection={collection} />

      {collection.privacySetting === 'private' && (
        <Lock className="absolute left-4 top-4 [&_path]:fill-white" />
      )}

      <BookmarkCollectionCardTooltip collection={collection} initialShowing={showTooltip} />

      <div className="flex flex-col gap-1">
        <Typography.Body3Bold className={twMerge('truncate', isFavorite && 'capitalize')}>
          {collection.name}
        </Typography.Body3Bold>
        <Typography.Body4>
          {collection.numVideos} video{collection.numVideos !== 1 && 's'}
        </Typography.Body4>
        {isFollowing && author !== 'self' && (
          <Typography.Body4>
            by {getMemberDisplayName({ profile: { ...author } }, { useFullNameWithInitial: true })}
          </Typography.Body4>
        )}
      </div>
    </Link>
  )
}
