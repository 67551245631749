import { Bookmark } from 'src/components/Icon/Bookmark'
import type { BookmarkCollectionType } from 'src/types/BookmarkCollections'

interface Props {
  collection: Omit<BookmarkCollectionType, 'obeClasses'>
}

export const BookmarkCollectionCardImage = ({ collection }: Props) => {
  return (
    <div className="relative flex h-48 flex-col items-center">
      {collection.thumbnailImage ? (
        <img
          src={collection.thumbnailImage}
          alt='collection thumbnail'
          className="absolute -top-3 -z-[1] h-full w-10/12 rounded-2xl "
        />
      ) : (
        <div className="absolute -top-3 bottom-0 -z-[1] w-10/12 rounded-2xl bg-gradient-blue" />
      )}
      <div className="absolute -top-3 bottom-0 -z-[1] w-10/12 rounded-2xl bg-black/25 backdrop-blur-sm" />

      {collection.thumbnailImage ? (
        <img
          src={collection.thumbnailImage}
          alt='collection thumbnail'
          className="absolute -top-2 -z-[1] h-full w-11/12 rounded-2xl"
        />
      ) : (
        <div className="absolute -top-2 bottom-0 -z-[1] w-11/12 rounded-2xl bg-gradient-blue" />
      )}
      <div className="absolute -top-2 bottom-0 -z-[1] w-11/12 rounded-2xl bg-black/15 backdrop-blur-sm" />

      {collection.thumbnailImage ? (
        <img
          src={collection.thumbnailImage}
          alt='collection thumbnail'
          className="h-full w-full rounded-2xl object-cover"
        />
      ) : (
        <div className="h-full w-full rounded-2xl bg-gradient-blue object-cover" />
      )}

      {!collection.thumbnailImage ? (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <Bookmark className="h-14 w-14 stroke-white" />
        </div>
      ) : null}
    </div>
  )
}
