import { mixin, breakpoint } from 'src/styles'
import { Link } from 'src/components/Link'
import { RichText } from 'src/components/Display/RichText'
import { UserAction } from 'src/components/UserAction'

export const CommonStyles = {
  Title: styled(RichText.Medium)`
    font-size: 32px;
    font-weight: 200;
    margin: 0 0 17px;
    padding: 0 1rem;

    ${({ hasNoPadding }) =>
      hasNoPadding &&
      css`
        padding: 0;
      `}
  `,
  CardSubtitle: styled.div`
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.85px;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    text-align: center;

    ${breakpoint.mediumAndDown} {
      margin-bottom: 2rem;
    }
  `,
  RedirectLink: styled(Link)`
    ${mixin.flexCenterBoth}
    text-decoration: underline;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--nearBlack);

    ${({ favoritesSeeAll, classHistorySeeAll }) =>
      (favoritesSeeAll || classHistorySeeAll) &&
      css`
        margin-top: 2rem;
        width: 100%;
      `}

    ${({ friendsSeeAll }) =>
      friendsSeeAll &&
      css`
        margin-top: 0.75rem;
        width: 100%;
      `}
  `,
  ViewMore: styled(UserAction)`
    ${mixin.flexCenterBoth}
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    text-decoration: underline;
    border-top: 1px solid #d0d0d0;
    letter-spacing: 0.5px;
    padding: 1rem;
    cursor: pointer;
  `,
  EmptyStateCta: styled.div`
    background-color: var(--lightGrey);
    padding: 1.5rem;
    font-size: 18px;
    letter-spacing: 0.005em;
    font-weight: 300;
    text-align: center;
    margin: 16px;

    ${({ hasNoMarginBottom }) =>
      hasNoMarginBottom &&
      css`
        margin-bottom: 0;
      `}
  `,
  CurrentWeek: styled(RichText.Large)`
    margin: -12px 0 16px 0;
  `,
}
