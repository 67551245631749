import { button, breakpoint, mixin } from 'src/styles'
import { CommonStyles } from './styles'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { getObeProgram } from 'src/models/program'
import { isEmpty } from 'lodash'
import { Link } from 'src/components/Link'
import { Loader } from 'src/components/Display/Loader'
import { useAsync } from 'react-async-hook'
import { useMemberContext } from 'src/providers/MemberProvider'
import { useResponsive } from 'src/hooks/useResponsive'

export const ProgramsProgress = () => {
  const { currentMember, currentProgramData, isSelf } = useMemberContext()
  const { isMediumAndUp } = useResponsive()

  const { result: currentProgramDetail } = useAsync(getObeProgram, [
    currentProgramData?.obeProgramId,
  ])

  const imageUrl = isMediumAndUp
    ? currentProgramDetail?.imageUrl
    : currentProgramDetail?.headerImage

  return (
    <Styles.Container>
      <CommonStyles.Title compact gibsonLight>
        Programs
      </CommonStyles.Title>
      {isMediumAndUp && (
        <CommonStyles.CardSubtitle>
          {isSelf
            ? 'MY ON-GOING PROGRAM'
            : `${getMemberDisplayName(currentMember, { useFirstName: true })}'S ON-GOING PROGRAM`}
        </CommonStyles.CardSubtitle>
      )}
      <Loader isLoading={isEmpty(currentProgramDetail) || isEmpty(currentProgramData)}>
        <Styles.CardContainer to={`/programs/${currentProgramDetail?.slug}`}>
          <Styles.Image src={imageUrl} />
          <Styles.TrackerContainer>
            <Styles.Title>{currentProgramDetail?.title}</Styles.Title>
            {isSelf && (
              <Styles.ResumeButton to={`/programs/${currentProgramDetail?.slug}`}>
                Work it out!
              </Styles.ResumeButton>
            )}
          </Styles.TrackerContainer>
        </Styles.CardContainer>
      </Loader>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.shadowContainer}
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    ${breakpoint.mediumAndUp} {
      padding: 16px 16px 25px 16px;
    }
  `,
  CardContainer: styled(Link)`
    display: flex;
    width: 600px;
    height: 280px;
    background: #ffffff;
    overflow: hidden;
    ${mixin.shadowContainer}
    :hover {
      text-decoration: none;
    }
    ${breakpoint.smallAndDown} {
      flex-direction: column;
      align-items: center;
      width: 315px;
      height: 80%;
      margin-bottom: 5px;
    }
  `,
  Image: styled.img`
    flex-shrink: 0;
    object-fit: cover;
    object-position: top center;
    ${breakpoint.mediumAndUp} {
      height: 280px;
      width: 250px;
      background-position: center center;
    }
    height: 110px;
    width: 315px;
  `,
  TrackerContainer: styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ${breakpoint.mediumAndUp} {
      padding: 20px 15px;
    }
  `,
  Title: styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0.3px;
    text-align: center;
    margin-bottom: 20px;

    ${breakpoint.smallAndDown} {
      font-size: 28px;
    }
  `,
  ResumeButton: styled(Link)`
    ${button.newPrimary}
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    ${breakpoint.smallAndDown} {
      width: 285px;
    }
  `,
}
