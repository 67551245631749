import moment from 'moment'

import { BookmarkWithDropdown } from 'src/components/BookmarkToggle/BookmarkWithDropdown'
import { Icon } from 'src/components/Icon'
import { Link } from 'src/components/Link'
import { ClassFormat } from 'src/constants'
import { useNavigationTracker } from 'src/providers/NavigationTrackerProvider'
import { mixin } from 'src/styles'
import { getEquipmentLabel } from 'src/utils/getEquipmentLabel'

const Container = ({ href, children, className, action, tracking }) => {
  const { setNavigatedFrom } = useNavigationTracker()

  const onClick = React.useCallback(() => {
    setNavigatedFrom(tracking?.from)
  }, [setNavigatedFrom, tracking])

  return href ? (
    <Styles.LinkContainer
      className={className}
      to={href}
      action={action}
      tracking={tracking}
      onClick={onClick}
    >
      {children}
    </Styles.LinkContainer>
  ) : (
    <Styles.Container className={className}>{children}</Styles.Container>
  )
}

export const ViewCard = ({ className, view, trackingContext = null }) => {
  const { imageUrl, title, subtitle, duration, href, isProgram, id, isArchived } = getData(view)

  const trackingAction = !isProgram ? obe.events.vod.viewed : undefined
  const trackingInfo = !isProgram
    ? { from: { fromPage: 'profile', fromDetail: 'classHistory' }, videoId: id }
    : undefined

  return (
    <Container className={className} href={href} action={trackingAction} tracking={trackingInfo}>
      {isArchived && <Styles.Overlay>This class is no longer available</Styles.Overlay>}
      <Styles.Duration>{duration}m</Styles.Duration>
      <Styles.Image backgroundUrl={imageUrl} />
      <Styles.Main>
        <div style={{ marginBottom: 8 }}>
          <Styles.Title>{title}</Styles.Title>
          <Styles.Subtitle>{subtitle}</Styles.Subtitle>
        </div>
        <div>
          <Styles.Date>{moment(view.viewStartTime).format('LL')}</Styles.Date>
          {view?.type === ClassFormat.LIVE && <Styles.LiveBadge>LIVE</Styles.LiveBadge>}
          {view?.type === ClassFormat.REPLAY && (
            <Styles.ReplayText>
              <Styles.Replay /> REPLAY
            </Styles.ReplayText>
          )}
          {(view?.type === ClassFormat.ON_DEMAND || view?.type === ClassFormat.AUDIO) && (
            <Styles.BookmarkToggle
              className={className}
              isClassHistory
              iconOnly
              direction='up'
              trackingContext={trackingContext}
              video={view?.video || view?.classDto}
            />
          )}
        </div>
      </Styles.Main>
    </Container>
  )
}

const getData = ({ classDto, type }) => {
  const isLive = type === ClassFormat.LIVE
  const isArchived = classDto?.status === 'archived'
  const isProgram = !!classDto?.program
  const href = isProgram ? `/programs/${classDto?.program?.slug}` : `/videos/${classDto?.id}`

  return {
    duration: classDto?.durationBucket,
    equipment: getEquipmentLabel(classDto?.equipment),
    eventClassType: classDto?.classType?.name,
    href: isLive || isArchived ? null : href,
    imageUrl: isLive ? classDto?.instructors?.[0]?.profilePhoto : classDto?.thumbnailUrl,
    instructors: classDto?.instructors,
    subtitle: classDto?.instructors?.map(({ name }) => name).join(', '),
    title: classDto?.classLengthTitle || classDto?.title,
    id: classDto?.id,
    isProgram,
    isArchived,
  }
}

const Styles = {
  Container: styled.div`
    align-items: stretch;
    background-color: white;
    ${mixin.shadowContainer}
    display: flex;
    position: relative;
  `,
  LinkContainer: styled(Link)`
    align-items: stretch;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    position: relative;

    :focus,
    :hover {
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
      outline: 0;
      text-decoration: none;
      z-index: 1;
    }
  `,
  Overlay: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(2, 3, 3, 0.3);
    color: var(--white);
    text-align: center;
  `,
  Duration: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 0.9rem;
    background-color: var(--ice);
    padding: 0.2rem 0.7rem;
    font-weight: 100;
  `,
  Image: styled.div`
    ${({ backgroundUrl }) => css`
      background-image: url(${backgroundUrl});
    `}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    min-height: 165px;
    width: 50%;
  `,
  Main: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 14px 14px 14px 16px;
    width: 50%;
    justify-content: space-between;
    font-weight: 300;
  `,
  Title: styled.div`
    font-size: 19px;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  `,
  Subtitle: styled.div`
    font-size: 14px;
    letter-spacing: 0.85px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  Date: styled.div`
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: lowercase;
    color: #979797;
    padding-right: 20px;
    margin-bottom: 7px;
  `,
  LiveBadge: styled.div`
    bottom: 2px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    position: absolute;
    right: 5px;
  `,
  BookmarkToggle: styled(BookmarkWithDropdown)`
    position: absolute;
    width: unset !important;
    bottom: 6px;
    right: 6px;
  `,
  ReplayText: styled.div`
    bottom: 2px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    position: absolute;
    right: 5px;
  `,
  Replay: styled(Icon.Replay)`
    margin-bottom: 4px;
    width: 13px;
    height: 13px;

    path {
      fill: var(--nearBlack);
    }
  `,
}
