export const getMemberDisplayName = (memberData, options = {}) => {
  const { username, firstName, lastName } = memberData?.profile || {}
  let finalDisplayName

  if (options.useFullNameWithInitial && firstName) {
    const initial = (lastName && `${lastName[0]}.`) || ''
    finalDisplayName = `${firstName} ${initial}`
  } else if (options.useFullName && firstName) {
    finalDisplayName = `${firstName} ${lastName}`
  } else if (options.useFirstName && firstName) {
    finalDisplayName = `${firstName}`
  } else {
    // fallback
    finalDisplayName = username
  }

  return formattedSentenceCase(finalDisplayName)
}

export const formattedSentenceCase = (text) =>
  text
    ?.toLowerCase()
    .replace(/\b(\w)/g, (str) => str.toUpperCase())
    .trim()

export const formattedFirstCharacterCapitalCase = (text) =>
  text
    ?.toLowerCase()
    .replace(/\b(\w)/, (str) => str.toUpperCase())
    .trim()

export const formatFileName = (text) => {
  const splitStr = text?.split('.')
  const fileExtension = splitStr.pop()
  return splitStr > 20 ? `${splitStr.slice(0, 20).join('')}...${fileExtension}` : text
}

export const formattedMemberLocation = (city, state) => {
  return city && state ? ` - ${city}, ${state}` : city ? ` - ${city}` : state ? ` - ${state}` : ''
}

export const formatWithLowerSentenceCase = (itemString) => {
  const itemArray = itemString?.split(' ')
  return (
    itemArray?.[0]?.[0]?.toUpperCase() +
    itemArray?.[0]?.slice(1) +
    ' ' +
    itemArray?.slice(1).join(' ').toLowerCase(0)
  )
}
export const formatTags = (tagNames) => {
  const formattedNames = tagNames?.map(formatWithLowerSentenceCase)
  return formattedNames?.map((tagName, index) => (
    <div key={index}>
      {tagName}
      <span style={{ padding: '0 8px' }}>{index !== formattedNames.length - 1 ? '|' : ''}</span>
    </div>
  ))
}

// Force some keywords to be uppercase
export const formatVideoTitle = (title) => {
  if (!title) return ''

  return (
    title
      // HIIT should be capitalized
      .replace(/hiit/gim, (str) => str.toUpperCase())
      .trim()
  )
}

export const getInstructorTags = (instructor) => instructor?.tags.map((tag) => tag.name).join(', ')
