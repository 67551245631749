import { breakpoint, mixin } from 'src/styles'
import { StatsAccordion, useStatsAccordionState } from 'src/views/Profile/StatsTab/components'

const MinutesPerClassType = ({ minutesPerClassType }) => {
  const {
    expanded,
    items: minutes,
    showToggle,
    onAccordionClick,
  } = useStatsAccordionState({ items: minutesPerClassType })

  if (!minutesPerClassType.length) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.HeaderText>Minutes per class type</Styles.HeaderText>
      <StatsAccordion expanded={expanded} showToggle={showToggle} onClick={onAccordionClick}>
        <Styles.MinutesContainer>
          {minutes.map((classTypeItem) => (
            <Styles.ClassContainer key={classTypeItem.classType}>
              <Styles.ClassTypeName>{classTypeItem.classType}</Styles.ClassTypeName>
              <Styles.ClassTypeMinutes>{`${classTypeItem.totalMinutes}-min`}</Styles.ClassTypeMinutes>
            </Styles.ClassContainer>
          ))}
        </Styles.MinutesContainer>
      </StatsAccordion>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    padding: 16px;

    & > *:last-child {
      border-bottom: none;
    }

    ${mixin.shadowContainer}
  `,
  MinutesContainer: styled.div`
    padding-bottom: 15px;
  `,
  ClassContainer: styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
  `,
  HeaderText: styled.div`
    font-size: 32px;
    line-height: 42px;
    font-weight: 300;
    color: var(--nearBlack);

    ${breakpoint.smallAndDown} {
      text-align: center;
    }
  `,
  ClassTypeName: styled.div`
    font-size: 16px;
    line-height: 16px;
    color: var(--nearBlack);
    font-weight: 400;
    margin: 24px 0;
  `,
  ClassTypeMinutes: styled.div`
    font-size: 16px;
    line-height: 16px;
    color: #2f85ff;
    font-weight: 400;
    margin: 24px 0;
  `,
}

export default MinutesPerClassType
