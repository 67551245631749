'use client'

import { cva, type VariantProps } from 'class-variance-authority'
import { type ButtonHTMLAttributes, type DetailedHTMLProps, type ReactNode } from 'react'

import { ButtonSpinner } from 'src/components/Button/ButtonSpinner'
import { cn } from 'src/utils/cn'

interface Props
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  children?: ReactNode
}

export const Button = ({
  variant = 'primary',
  size = 'default',
  isLoading,
  leftIcon,
  rightIcon,
  children,
  className,
  ...props
}: Props) => {
  return (
    <button className={cn(buttonVariants({ variant, size, className }))} {...props}>
      {isLoading ? (
        <ButtonSpinner variant={variant} />
      ) : (
        <>
          {leftIcon}
          {children}
          {rightIcon}
        </>
      )}
    </button>
  )
}

export const buttonVariants = cva(
  'flex items-center justify-center gap-0.5 flex-shrink-0 rounded-full text-base lg:font-medium ring-offset-primary-400 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 leading-[1.125rem] lg:text-lg lg:leading-tight text-center hover:outline-none',
  {
    variants: {
      variant: {
        primary:
          'bg-primary-400 text-white hover:bg-primary-500 focus:bg-primary-500 disabled:bg-obe-neutral-500 disabled:text-obe-neutral-700 stroke-white disabled:[&_path]:stroke-obe-neutral-700',
        secondary:
          'bg-white text-primary-400 [&_path]:stroke-primary-400 border-primary-400 hover:text-primary-500 hover:border-primary-500 focus:text-primary-500 focus:border-primary-500 disabled:bg-obe-neutral-300 disabled:text-obe-neutral-700 disabled:border-obe-neutral-500 border-2 disabled:[&_path]:stroke-obe-neutral-700',
        destructive:
          'border-2 text-feedback-200 bg-obe-neutral-100 border-feedback-200 hover:text-white focus:text-white hover:bg-feedback-200 focus:bg-feedback-200 disabled:bg-obe-neutral-500 disabled:text-obe-neutral-700 disabled:[&_path]:stroke-obe-neutral-700 stroke-white',
        icon: 'hover:text-primary-500 focus:text-primary-500 disabled:text-obe-neutral-600 min-w-fit !py-4 !px-8',
        link: 'disabled:text-obe-neutral-700 bg-transparent hover:underline focus:underline',
      },
      size: {
        default: 'min-w-[210px] !py-4 !px-8',
        sm: 'min-w-[86px] !py-3 !px-6 text-sm font-medium',
        lg: 'min-w-[344px] !py-4 !px-8',
        fit: 'min-w-fit !py-4 !px-8',
        full: 'w-full !py-4 !px-8',
      },
    },
    compoundVariants: [
      {
        variant: 'icon',
        size: ['default', 'sm', 'lg', 'fit', 'full'],
        class: 'min-w-fit min-h-fit h-fit w-fit !p-0',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
)

export type ButtonVariant = VariantProps<typeof buttonVariants>['variant']
export type ButtonSize = VariantProps<typeof buttonVariants>['size']
