const useStatsAccordionState = ({ items }) => {
  const [expanded, setExpanded] = React.useState(false)

  const accordionItems = React.useMemo(() => {
    if (expanded || items?.length < 4) {
      return items
    }
    return items?.filter((_, i) => i < 3)
  }, [expanded, items])

  const onAccordionClick = () => setExpanded((prev) => !prev)

  return {
    expanded,
    items: accordionItems,
    showToggle: items?.length > 3,
    onAccordionClick,
  }
}

export default useStatsAccordionState
