import { api, client } from 'src/api'

export const getClassesTaken = async () => {
  if (!api.sessionState) return null
  const { data } = await client.get<{
    id: string
    email: string
    classesTaken: Record<string, { isClassCompleted: boolean }>
  }>('/api/v3/obe_class_views/classes_taken')
  return data.classesTaken
}

export const sendVideoCompletion = async (obeClassId: string) => {
  return await client.post<void>('/api/v3/obe_class_views/client_tracked_view', {
    obe_class_id: obeClassId,
  })
}

export const getStats = async () => {
  if (!api.sessionState) return
  const { data } = await client.get<{
    classesPerInstructor: {
      id: string
      instructorName: string
      avatarUrl: string
      slug: string
      totalClassesPerInstructor: number
    }[]
    minutesPerClassType: Record<string, number>[]
    totalClasses: number
    totalMinutes: number
    totalClassTypes: number
  }>('/api/v3/obe_class_views/stats')
  return data
}
