import { COLLECTION_TYPES } from 'src/models/collections'

export const isCollectionPrivate = (privacySetting) =>
  privacySetting?.toUpperCase() === COLLECTION_TYPES.PRIVATE.ALL_CAPS

export const checkIsCollectionPublic = (bookmarkCollections, collectionId) => {
  if (bookmarkCollections?.length > 0) {
    return bookmarkCollections?.some((collection) => collection.id === collectionId)
  }
}

export const isClassReplayOrVOD = (video = {}) => (video?.isReplay ? 'replay' : 'vod')
