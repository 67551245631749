const StatsAccordion = ({ onClick, expanded = false, showToggle, children }) => {
  return (
    <Styles.Container>
      <Styles.ItemsContainer expanded={expanded}>{children}</Styles.ItemsContainer>
      {showToggle && (
        <Styles.Toggle role='button' onClick={onClick}>
          {expanded ? 'Hide all' : 'See all'}
        </Styles.Toggle>
      )}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    position: relative;
  `,
  ItemsContainer: styled.div`
    ${({ expanded }) =>
      expanded &&
      css`
        max-height: 540px;
        overflow-y: auto;
      `}
  `,
  Toggle: styled.div`
    text-align: center;
    padding-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
  `,
}

export default StatsAccordion
