import { useMemo } from 'react'
import useSwr, { useSWRConfig } from 'swr'

import { MemberService } from 'src/services'

export const useWeeklyClassActivity = (userId, isSelf = false) => {
  const { cache } = useSWRConfig()

  const USER_KEY = useMemo(
    () =>
      MemberService.getSWRKey({
        method: MemberService.methods.GetWeeklyClassActivity,
        params: { userId: isSelf ? `me/${obe.storage_keys.swr_me_key}` : userId },
      }),
    [isSelf, userId],
  )

  const { data, error } = useSwr(USER_KEY, () => MemberService.getWeeklyClassActivity({ userId }), {
    revalidateOnMount: !cache.has(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const isLoadingInitialState = !data && !error
  const defaultResponse = {
    weeklyClassActivity: {},
    isLoadingInitialState,
  }

  if (error || !data) {
    return defaultResponse
  }

  return {
    weeklyClassActivity: data,
  }
}

export default useWeeklyClassActivity
