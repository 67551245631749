import useSavedClasses from 'src/hooks/useSavedClasses'
import { COLLECTION_TYPES } from 'src/models/collections'
import { useMemberContext } from 'src/providers/MemberProvider'
import { BookmarkCollectionCard } from 'src/views/Profile/BookmarkCollections/BookmarkCollectionCard'

interface Props {
  image: string | undefined
}

export const BookmarkCollectionAllCard = ({ image }: Props) => {
  const { currentMember, isSelf } = useMemberContext()
  const { savedVideos } = useSavedClasses(
    !isSelf && currentMember?.id ? currentMember.id : undefined,
  )

  return (
    <BookmarkCollectionCard
      collection={{
        id: COLLECTION_TYPES.ALL.LOWERCASE,
        name: COLLECTION_TYPES.ALL.UPPERCASE,
        numVideos: savedVideos.length,
        privacySetting: 'private',
        userId: currentMember?.id ?? '',
        isRequired: false,
        isStack: false,
        numUpdates: 0,
        thumbnailImage: image,
      }}
    />
  )
}
