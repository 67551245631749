import { useStats } from 'src/hooks/useStats'
import { Link } from 'src/components/Link'
import { Icon } from 'src/components/Icon'

export const EmptyStatsBanner = () => {
  const { data: statsData, isValidating: isLoading } = useStats()
  const hasEmptyStats = !statsData?.totalMinutes && !isLoading

  if (!hasEmptyStats) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.IconContainer>
        <Icon.EmptyStats />
      </Styles.IconContainer>
      <Styles.ContentContainer>
        <Styles.Heading>Let’s get moving!</Styles.Heading>
        <Styles.Description>
          Head to Classes and take a class to start tracking your stats.
        </Styles.Description>
        <Styles.Link to='/videos'>See classes</Styles.Link>
      </Styles.ContentContainer>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    display: flex;
    border: 1px var(--neutrals500) solid;
    border-radius: 16px;
    padding: 16px;
    background: var(--gradientLight300);
    flex-direction: row;
    align-items: center;
  `,
  IconContainer: styled.div`
    width: 52px;
  `,
  ContentContainer: styled.div`
    flex: 1;
    flex-direction: column;
    padding-left: 16px;
  `,
  Heading: styled.div`
    font-style: normal;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.3px;
    line-height: 36px;
    margin-bottom: 8px;
  `,
  Description: styled.p`
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 6px;
    max-width: 270px;
  `,
  Link: styled(Link)`
    text-decoration: underline;
  `,
}
