import { TotalNumbers } from 'src/views/Profile/StatsTab/components/index'
import { useStats } from 'src/hooks/useStats'

const StatsTotalNumbersView = () => {
  const { data, isValidating: isLoading } = useStats()
  const hasEmptyStats = !data?.totalMinutes && !isLoading

  if (hasEmptyStats) {
    return null
  }

  if (isLoading) {
    return <div />
  }

  return (
    <Container>
      <TotalNumbers
        showSeeAll={true}
        totalClasses={data?.totalClasses}
        totalMinutes={data?.totalMinutes}
        totalClassTypes={data?.totalClassTypes}
        isOverview
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
`

export default StatsTotalNumbersView
