import { useAsync } from 'react-async-hook'

import { Link } from 'src/components/Link'
import { Spinner } from 'src/components/Spinner'
import useBookmarkCollections from 'src/hooks/useBookmarkCollections'
import { useMediaQuery } from 'src/hooks/window'
import { getAssetUrlCollectionBySlug } from 'src/models/asset'
import { useMemberContext } from 'src/providers/MemberProvider'
import { breakpointMediaShort } from 'src/styles'
import { isFavoritesCollection } from 'src/utils/isFavoritesCollection'
import { BookmarkCollectionAllCard } from './BookmarkCollections/BookmarkCollectionAllCard'
import { BookmarkCollectionCard } from './BookmarkCollections/BookmarkCollectionCard'
import { CommonStyles } from './styles'

export const CollectionsOverview = () => {
  const { currentMember, isSelf, username } = useMemberContext()
  const { bookmarkCollections } = useBookmarkCollections(
    !isSelf && currentMember?.id ? currentMember.id : undefined,
  )
  const isMediumAndUp = useMediaQuery(breakpointMediaShort.mediumAndUp)

  const { result: assets, loading } = useAsync(getAssetUrlCollectionBySlug, [
    'bookmark-collections',
  ])

  return (
    <div className="flex flex-col items-center gap-4 rounded-2xl bg-white !p-4 shadow-obe-neutral-xxs">
      <CommonStyles.Title compact centraLight>
        Collections
      </CommonStyles.Title>
      <CommonStyles.CardSubtitle>
        {isSelf ? 'MY SAVED CLASSES' : `${currentMember?.profile?.firstName}'S SAVED CLASSES`}
      </CommonStyles.CardSubtitle>
      {loading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {isSelf && isMediumAndUp && (
            <BookmarkCollectionAllCard image={assets?.['default-collections-placeholder']} />
          )}
          {bookmarkCollections?.slice(0, isSelf || !isMediumAndUp ? 1 : 2).map((collection) => {
            const isFavorite = isFavoritesCollection(collection?.name) && collection.isRequired

            return (
              <BookmarkCollectionCard
                key={collection.id}
                collection={
                  isFavorite
                    ? {
                        ...collection,
                        thumbnailImage: assets?.['favorites-collection-placeholder'],
                      }
                    : collection
                }
                isFavorite={isFavorite}
              />
            )
          })}
        </div>
      )}
      <Link to={`${username}/collections`} tracking={{ context: 'profile_see_all_collections' }}>
        See All
      </Link>
    </div>
  )
}
