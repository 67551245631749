import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const SpinnerIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      className={twMerge('fill-none', className)}
      {...props}
    >
      <path
        d='M1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
