import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Lock = ({ className }: SVGProps<SVGSVGElement>) => (
  <svg
    width='13'
    height='17'
    viewBox='0 0 13 17'
    xmlns='http://www.w3.org/2000/svg'
    className={twMerge('[&_path]:fill-black', className)}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.18827 6.56185C1.75349 6.56185 1.3 6.96992 1.3 7.60475V14.6524C1.3 15.2872 1.75349 15.6953 2.18827 15.6953H10.8117C11.2465 15.6953 11.7 15.2872 11.7 14.6524V7.60475C11.7 6.96992 11.2465 6.56185 10.8117 6.56185H2.18827ZM0 7.60475C0 6.36684 0.923768 5.25708 2.18827 5.25708H10.8117C12.0762 5.25708 13 6.36684 13 7.60475V14.6524C13 15.8903 12.0762 17 10.8117 17H2.18827C0.923768 17 0 15.8903 0 14.6524V7.60475Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.49961 1.30477C5.03289 1.30477 3.89961 2.42785 3.89961 3.68132V5.87147C3.89961 6.23177 3.60859 6.52386 3.24961 6.52386C2.89062 6.52386 2.59961 6.23177 2.59961 5.87147V3.68132C2.59961 1.61787 4.40733 0 6.49961 0C8.59189 0 10.3996 1.61787 10.3996 3.68132V5.87147C10.3996 6.23177 10.1086 6.52386 9.74961 6.52386C9.39063 6.52386 9.09961 6.23177 9.09961 5.87147V3.68132C9.09961 2.42785 7.96633 1.30477 6.49961 1.30477Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.50039 9.82373C6.85938 9.82373 7.15039 10.1158 7.15039 10.4761V11.7809C7.15039 12.1412 6.85938 12.4333 6.50039 12.4333C6.14141 12.4333 5.85039 12.1412 5.85039 11.7809V10.4761C5.85039 10.1158 6.14141 9.82373 6.50039 9.82373Z'
    />
  </svg>
)
